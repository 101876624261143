import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConfigService } from '../config/config.service';
import { ValidDomaine } from 'src/app/interfaces/config.interface';
import { UserService } from '../user/user.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private API_CONFIG: any;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private userService: UserService
  ) {
    this.API_CONFIG = this.configService.get('API_CONFIG');
  }

  async loginLocal(username: string, password: string) {
    this.userService.logout();

    const formData = new FormData();
    formData.append('client_id', this.API_CONFIG.CLIENT_ID);
    formData.append('grant_type', 'password');
    formData.append('username', username);
    formData.append('password', password);

    const response = await this.http.post(`${this.API_CONFIG.API_URL}/v3.0/oauth2/token/`, formData, {}).toPromise();

    if (response['access_token']) {
      localStorage.setItem('access_token', response['access_token']);
    } else {
      localStorage.removeItem('access_token');
    }
  }

  async redirectSSO(domaine: ValidDomaine) {
    this.userService.logout();

    const partnerDetails = await this.getPartnerDetails(domaine);
    const currentUrl: URL = this.getCurrentUrl(domaine);

    const ssoUrl = new URL(partnerDetails['authorization_code_url']);
    ssoUrl.searchParams.set('redirect_uri', currentUrl.toString());

    window.open(ssoUrl.toString(), '_self');
  }

  async loginSSO(domaine: ValidDomaine, code: string) {
    this.userService.logout();

    const partnerDetails = await this.getPartnerDetails(domaine);
    const currentUrl: URL = this.getCurrentUrl(domaine);
    const client = this.configService.get(domaine.toLocaleUpperCase() as Uppercase<typeof domaine>);

    const external: any = await this.http
      .post(
        partnerDetails['authorization_token_url'],
        new URLSearchParams({
          grant_type: 'authorization_code',
          code: code,
          client_id: client['CLIENT_ID'],
          redirect_uri: currentUrl.toString()
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Basic ${btoa(`${client['CLIENT_ID']}:${client['CLIENT_SECRET']}`)}`
          }
        }
      )
      .toPromise();

    const convert = await this.http
      .post(
        `${this.API_CONFIG.API_URL}/v3.0/oauth2/convert-token`,
        new URLSearchParams({
          grant_type: 'convert_token',
          token: external['access_token'],
          backend: domaine,
          client_id: this.API_CONFIG.CLIENT_ID
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )
      .toPromise();

    localStorage.setItem('access_token', convert['access_token']);
  }

  async sudo(user_id: number) {
    const response = await this.http.post(`${this.API_CONFIG.API_URL}/v3.0/users/${user_id}/sudo`, {}).toPromise();

    localStorage.setItem('access_token', response['access_token']);
    location.reload();
  }

  private async getPartnerDetails(domaine: string) {
    return await this.http.get(`${this.API_CONFIG.API_URL}/v3.0/partners/${domaine}`).toPromise();
  }

  private getCurrentUrl(domaine: string): URL {
    const currentUrl = new URL(location.href);
    currentUrl.search = '';
    currentUrl.searchParams.append('domaine', domaine);
    currentUrl.searchParams.delete('code');
    currentUrl.hash = '';
    return currentUrl;
  }
}
